import { useEffect, useState } from "react"

export const useRequest = <T>(request: () => Promise<T>) => {
    const [data, setData] = useState<T>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<unknown>()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request()
                setData(response)
            } catch (e) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [request])
    return { data, loading, error }
}
