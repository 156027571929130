import { Button, Container, Card, CardContent, CssBaseline, ThemeProvider } from "@mui/material";
import { AppContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import LoginIcon from '@mui/icons-material/Login';
import { DefaultTheme } from "../themes/Default";
import Shell from "../layout/Shell";

const ErrorPage: React.FC = () => {
    const { user, loginWithRedirect, logout } = useAuth0()
    return (
        <>
            <CssBaseline />
            <AppContext.Provider value={{}}>
                <ThemeProvider theme={DefaultTheme}>
                    <Shell hideIcons={true}>
                        <Container>
                            <Card>
                                <CardContent>
                                    <p>You've been logged out due to inactivity</p>
                                    <Button startIcon={<LoginIcon />} onClick={() => {
                                        if (user) {
                                            logout()
                                        } else {
                                            loginWithRedirect()
                                        }
                                    }}>Login</Button>
                                </CardContent>
                            </Card>
                        </Container>
                    </Shell>
                </ThemeProvider>
            </AppContext.Provider >
        </>
    )
};

export default ErrorPage;
