import { useEffect, useRef, useState } from 'react'
import { Popup, PopupProps, useMap } from 'react-map-gl'
import { TSourceFeatureData, findAndReplaceLayerTemplateString } from './templateString'
import MDEditor from '@uiw/react-md-editor'
import { THoverAdditions } from '../../pages/Map'

export const HoverState = ({ source, sourceLayer, layerId, templateStrings, hoverAdditions }: { source: string, sourceLayer: string, layerId: string, templateStrings: Map<string, string>, hoverAdditions?: THoverAdditions }) => {
    const { current: map } = useMap()
    const { current } = useRef<{ featureId: string | number | undefined }>({ featureId: undefined })
    const [popupProps, setPopupProps] = useState<PopupProps | undefined>();

    useEffect(() => {
        if (current && map) {
            // TODO: Add a proper type to this event
            const mouseEnter = (e: any) => {
                if (e.features && e.features?.length > 0) {
                    if (current) {
                        current.featureId = e.features[0].id
                        map?.setFeatureState({ source, sourceLayer, id: current.featureId }, { hover: true })
                        const sourceFeatureData: TSourceFeatureData = {
                            layer: sourceLayer,
                            properties: e.features[0].properties
                        }
                        let popupMarkdown = findAndReplaceLayerTemplateString(templateStrings, sourceFeatureData)

                        if (hoverAdditions && hoverAdditions.prefix) {
                            popupMarkdown = hoverAdditions.prefix + " " + popupMarkdown
                        }

                        if (popupMarkdown === '') {
                            // TODO: Should this render something?
                            return
                        }



                        setPopupProps({
                            longitude: e.lngLat.lng,
                            latitude: e.lngLat.lat,
                            children: <MDEditor.Markdown source={popupMarkdown} style={{ whiteSpace: 'pre-wrap' }} />
                        });
                    }
                }
            }
            // TODO: Add a proper type to this event
            const mouseLeave = (e: any) => {
                if (current) {
                    setPopupProps(undefined)
                    map?.removeFeatureState({ source, sourceLayer, id: current.featureId }, 'hover')
                }
            }
            map.on('mouseenter', layerId, mouseEnter)
            map.on('mouseleave', layerId, mouseLeave)
            return () => {
                map.off('mouseenter', layerId, mouseEnter)
                map.off('mouseenter', layerId, mouseLeave)
            }
        }
    }, [current, hoverAdditions, layerId, map, source, sourceLayer, templateStrings])

    if (popupProps) {
        return (
            <Popup {...popupProps}
                onClose={(e) => {
                    console.log(`Popup.onClose(e)\n${JSON.stringify(e, null, 2)}`)
                    setPopupProps(undefined)
                }} />
        )
    }
    return <></>
}
