import axios from 'axios'
import { TFileUpload } from '../hooks/useOrganization'

const BASE_URL = `${process.env.REACT_APP_API_SERVER_URL}/v1/validations`

export type TValidation = {
    id: string
    rule: string
    message: string
    relatedFeatureIds: string[]
}

export const startValidations = async (accessToken: string, organizationId: string, uploadId: string, validationRuleConfigs: TFileUpload['validationRuleConfigs'], layerName?: string, featureId?: string) => {
    return await axios.post<any>(
        `${BASE_URL}/start`,
        {
            UploadId: uploadId,
            LayerName: layerName,
            FeatureId: featureId,
            ValidationRuleConfigs: validationRuleConfigs
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}
