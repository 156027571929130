import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Tooltip } from '@mui/material';
import { ResizableBox } from 'react-resizable';



// Define the type for your tab
export type TabItem = {
    id: number;
    tooltip?: string;
    label?: string;
    icon: any;
    disabled: boolean;
    content: React.ReactNode;
};

// Define the props for your SidePanelTabs component
type SidePanelTabsProps = {
    tabs: TabItem[];
    activeTab: number,
    setActiveTab: (value: number) => void
};

const SidePanelTabs: React.FC<SidePanelTabsProps> = ({ tabs, activeTab, setActiveTab }) => {

    const [width, setWidth] = useState<number>(400); // Initial width of the sidebar

    const handleTabChange = (event: React.SyntheticEvent | null, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <ResizableBox
            width={width}
            height={window.innerHeight - 64}
            minConstraints={[225, Infinity]}
            maxConstraints={[1000, Infinity]}
            onResizeStop={(e, data) => setWidth(data.size.width)}
            handle={
                <div
                    style={{
                        width: '10px',
                        backgroundColor: 'white',
                        cursor: 'ew-resize',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                />
            }
            style={{
                position: 'absolute',
                height: 'calc(100vh - 64px)',
                zIndex: 1000,
                borderRight: '1px solid #ddd',
                boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'white'
            }}
        >
            <Grid container flexDirection={'row'} spacing={2} sx={{ 'padding': '10px', height: 'calc(100vh - 64px)' }}>
                <Grid item xs={2}>
                    <Tabs
                        sx={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            height: '100%'
                        }}
                        orientation="vertical"
                        value={activeTab}
                        onChange={handleTabChange}
                    >
                        {tabs.map((tab, tIdx) => (
                            <Tooltip key={tab.id} title={tab.tooltip} arrow sx={{
                                ...(tab.tooltip === 'Settings' ? { position: 'absolute', bottom: 0 } : {})
                            }}>
                                <Tab
                                    onClick={() => handleTabChange(null, tab.id)}
                                    disableRipple={tab.disabled}
                                    icon={tab.icon}
                                    label={tab.label}
                                    style={{
                                        fontSize: '10px',
                                        minWidth: '30px',
                                        cursor: tab.disabled ? 'default' : 'pointer',
                                    }}
                                />
                            </Tooltip>
                        ))}
                    </Tabs>
                </Grid>
                <Grid xs={10} item >
                    {tabs.map((tab, index) => (
                        <div style={{
                            overflow: 'auto',
                            height: 'calc(100vh - 90px)'
                        }} key={tab.id} hidden={tab.id !== activeTab}>
                            {tab.content}
                        </div>
                    ))}

                </Grid>
            </Grid>

        </ResizableBox>
    );
};

export default SidePanelTabs
