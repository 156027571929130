import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { forwardRef, useImperativeHandle } from 'react';
import { useControl } from 'react-map-gl';

import type { ControlPosition } from 'react-map-gl';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
    position?: ControlPosition;
    onCreate: (evt: { features: object[] }) => void
    onUpdate: (evt: { features: object[]; action: string }) => void
    onDelete: (evt: { features: object[] }) => void
};

const handleDrawModeChange = (mapboxDraw: MapboxDraw) => {
    return () => {
        const mode = mapboxDraw.getMode();
        const selectedFeatureIds = mapboxDraw.getSelectedIds();

        // If no feature is selected, revert to simple-select with the last selected feature
        if (mode !== 'simple_select' && selectedFeatureIds.length > 0) {
            mapboxDraw.changeMode('simple_select', {
                featureIds: selectedFeatureIds,
            });
        }
    }

};

export const DrawControl = forwardRef((props: DrawControlProps, ref) => {
    const mapboxDraw = useControl<MapboxDraw>(
        () => new MapboxDraw(props),
        ({ map }) => {
            const onModeChange = handleDrawModeChange(mapboxDraw);

            map.on('draw.create', props.onCreate)
            map.on('draw.update', props.onUpdate)
            map.on('draw.delete', props.onDelete)
            map.on('draw.modechange', onModeChange)
        },
        ({ map }) => {
            const onModeChange = handleDrawModeChange(mapboxDraw);

            map.off('draw.create', props.onCreate)
            map.off('draw.update', props.onUpdate)
            map.off('draw.delete', props.onDelete)
            map.off('draw.modechange', onModeChange)
        }
    )
    useImperativeHandle(ref, () => mapboxDraw, [mapboxDraw])
    return <></>
})

DrawControl.defaultProps = {
    onCreate: () => { },
    onUpdate: () => { },
    onDelete: () => { },
}
