import { Fragment } from "react/jsx-runtime";
import { TLayer, TRoadConfig, TRoadLabels } from "../../hooks/useOrganization";
import { Layer, Source } from "react-map-gl";
import { HoverState } from "./HoverState";
import { TLayerSelection } from "./tabs/SelectLayerTab";
import { THoverAdditions } from "../../pages/Map";
import { RoadLabelsLayer } from "../../pages/public/MapPage";

export const Layers = ({
    layers,
    selectedLayers,
    layerUrlFromName,
    layerUrlClassifiedFromName,
    templateStringMap,
    firstSymbolLayerId,
    hoverAdditions,
    roadLabels,
    roadConfig,
}: {
    layers: TLayer[],
    selectedLayers: TLayerSelection[],
    layerUrlFromName: (layerName: string) => string,
    layerUrlClassifiedFromName: (layerName: string) => string,
    templateStringMap: Map<string, string>,
    firstSymbolLayerId?: string,
    hoverAdditions?: THoverAdditions,
    roadLabels?: TRoadLabels,
    roadConfig?: TRoadConfig,
}) => {
    // NOTE: The roadConfig structure may need to change a bit, but this will work for now...
    const roadConfigPropName = roadConfig && Array.isArray(roadConfig.styles) && roadConfig.styles.length > 0
        ? roadConfig.styles[0].propertyName : null;

    const getLayerColor = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        const defaultColor = 'rgba(0, 0, 0, 0)';
        if (!info) {
            return defaultColor
        }

        return info?.rgb1()
    }

    const getLayerHeatMapEnd = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        if (!info) {
            return 'rgba(0, 0, 0, 0)'

        }

        return info?.rgb1()
    }

    const getLayerHeatMapStart = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        if (!info) {
            return 'rgba(0, 0, 0, 0)'
        }

        return info?.rgb0()
    }

    const mapLayers = (
        layers: TLayer[],
    ): any[] => {
        return layers.filter(layer => layer.type.includes('Polygon') || layer.type.includes('Line') || layer.type.includes('Point')).map((layer) => {
            if (layer.type.includes('Polygon')) {
                return {
                    name: layer.name,
                    minzoom: 4,
                    maxzoom: 20,
                    layers: [
                        {
                            suffix: '0',
                            type: 'line',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                        },
                        {
                            suffix: '1',
                            type: 'fill',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'fill-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], .3, .2],
                            },
                        },
                    ],
                }
            }
            if (layer.type.includes('Line')) {
                // get default color for line layer
                const defaultLineLayerColor = getLayerColor(layer.name);
                // build matchExpression rules from config
                const matchRules = roadConfigPropName ? roadConfig?.styles
                    .filter((s) => s.propertyName === roadConfigPropName)
                    .filter((x) => x.propertyName && x.propertyValue && x.color) // filter out invalid styles
                    .map((s) => [s.propertyValue, s.color])
                    .flat() || [] : []
                // apply line-color expression based on roadConfig
                const lineColorExpression = roadConfigPropName ? [
                    'match', ['get', roadConfigPropName], // ex: 'RoadClass'
                    ...matchRules, // ex: ['Primary', 'yellow', 'Local', 'light grey', ...]
                    defaultLineLayerColor
                ] : [defaultLineLayerColor];

                return layer.name === roadConfig?.layerName ? {
                    name: layer.name,
                    minzoom: 0,
                    maxzoom: 23,
                    // NOTE: I don't understand why we have two layers here. We should try to simplify.
                    layers: [
                        {
                            suffix: '1',
                            type: 'line',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': [
                                    ...lineColorExpression
                                ],
                                'line-width': [
                                    'interpolate',
                                    ['exponential', 2],
                                    ['zoom'],
                                    10, ["*", 10, ["^", 2, -2]],
                                    24, ["*", 10, ["^", 2, 8]]
                                ],
                            }
                        },
                        {
                            suffix: '2',
                            type: 'line',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': [
                                    'case', ['boolean', ['feature-state', 'click'], false],
                                    'cyan', [
                                        ...lineColorExpression
                                    ]
                                ],
                                'line-width': [
                                    'interpolate',
                                    ['exponential', 2],
                                    ['zoom'],
                                    10, ["*", 8, ["^", 2, -2]],
                                    24, ["*", 8, ["^", 2, 8]]
                                ],
                            }
                        },
                    ],
                } : {
                    name: layer.name,
                    minzoom: 8,
                    maxzoom: 20,
                    layers: [
                        {
                            suffix: '0',
                            type: 'heatmap',
                            minzoom: 0,
                            maxzoom: 10,
                            paintSelected: {
                                'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, getLayerHeatMapStart(layer.name), 1, getLayerHeatMapEnd(layer.name)],
                                'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                            },
                        },
                        {
                            suffix: '1',
                            type: 'line',
                            minzoom: 9,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                        },
                    ],
                }
            }
            return {
                name: layer.name,
                minzoom: 8,
                maxzoom: 20,
                layers: [
                    {
                        suffix: '0',
                        type: 'heatmap',
                        minzoom: 0,
                        maxzoom: 13,
                        paintSelected: {
                            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                            'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, getLayerHeatMapStart(layer.name), 1, getLayerHeatMapEnd(layer.name)],
                        },
                    },
                    {
                        suffix: '1',
                        type: 'circle',
                        minzoom: 13,
                        maxzoom: 23,
                        paintSelected: {
                            'circle-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                            'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                        },
                    },
                ],
            }
        })
    }

    const renderLayers = mapLayers(layers)
    const orderMap: Record<string, { order: number, show: boolean }> = {};
    selectedLayers.forEach((ls) => orderMap[ls.layerInfo.name] = { order: ls.selectionOrder, show: ls.checked })
    return (
        <>
            {
                renderLayers
                    .map((l) => {
                        const sourceId = l.name
                        const layerId = l.name
                        const isPointLayer = l.layers.findIndex((x: any) => x.type === 'heatmap' || x.type === 'circle') > -1;
                        let tileUrl = roadConfig ? layerUrlClassifiedFromName(l.name) : layerUrlFromName(l.name)
                        if (isPointLayer) {
                            tileUrl += '?sample=1000';
                        }

                        return (
                            <Fragment key={l.name}>
                                <Source id={sourceId} type="vector" tiles={[tileUrl]} promoteId="_id">
                                    {l.layers.map((layer: any) => {
                                        return (orderMap[sourceId] && orderMap[sourceId].show ?
                                            <Layer key={`${layerId}-${layer.suffix}`} id={`${layerId}-${layer.suffix}`} beforeId={firstSymbolLayerId} minzoom={layer.minzoom} maxzoom={layer.maxzoom} source-layer={l.name} type={layer.type} paint={layer.paintSelected} />
                                            :
                                            <Layer layout={{ "visibility": 'none' }} key={`${layerId}-${layer.suffix}`} id={`${layerId}-${layer.suffix}`} beforeId={firstSymbolLayerId} minzoom={layer.minzoom} maxzoom={layer.maxzoom} source-layer={l.name} type={layer.type} paint={layer.paintSelected} />
                                        )
                                    })}
                                    {roadLabels && roadLabels.layerName === l.name && <RoadLabelsLayer layer={l} roadLabels={roadLabels} />}
                                </Source>
                                {roadLabels?.layerName !== l.name && <HoverState source={sourceId} sourceLayer={l.name} layerId={`${layerId}-1`} templateStrings={templateStringMap} hoverAdditions={hoverAdditions} />}
                            </Fragment>
                        )
                    })}
        </>
    )
}
