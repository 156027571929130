import { useContext } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { AppContext } from "../App"
import Shell from '../layout/Shell'
import { Container } from "@mui/material"
import { TFileUpload, TOrganization, TOrganizationResult, getOrganizations } from "../hooks/useOrganization"
import FeatureTable from "../components/FeatureTable"
import { PageLoader } from "../components/page-loader"
import useQueryParams from "../hooks/useQueryParams";


const FileFeatures: React.FC<{}> = () => {
    const { accessToken } = useContext(AppContext)
    const { fileUploadId } = useParams<{ currentOrganizationId: string, fileUploadId: string }>()
    const qp = useQueryParams();
    const currentOrganizationId =  qp.get("organizationId")
    const { data: organizations } = useQuery<TOrganizationResult | Error>('get-organizations', () =>
        getOrganizations(accessToken!)
    )

    if (!organizations) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        )
    }

    if (organizations instanceof Error || !organizations.belongs) {
        return (<div>Error: {JSON.stringify(organizations, null, 2)}</div>)
    }


    const findFile = () => {
        const organization = organizations.organizations.find((org: TOrganization) => org.id === currentOrganizationId)

        if (!organization) {
            console.error("No matching organization, this is bad")
        }

        const file = organization?.fileUploads.find((file: TFileUpload) => file.uploadId === fileUploadId)

        return file;
    }

    const file = findFile();

    if (!file) {
        console.error("No matching file, this is also bad")
        return <></>
    }


    return (
        <Shell>
            <Container style={{ maxWidth: '2000px' }} >
                <FeatureTable currentOrganizationId={currentOrganizationId!} file={file}></FeatureTable>
            </Container>
        </Shell >
    )
}

export default FileFeatures;
