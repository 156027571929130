import { createTheme } from "@mui/material";

// Default MUI theme: https://mui.com/material-ui/customization/palette/#default-values
export const DefaultTheme = createTheme({
    palette: {
        primary: {
            main: '#0025f5',
        },
        secondary: {
            main: '#d03b3d',
        },
    },
})
