import React, { useContext, useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent, useTheme } from '@mui/material';
import { AppContext } from '../App';
import { useHistory } from 'react-router-dom'


const OrganizationSelector: React.FC<{}> = () => {
    const theme = useTheme();
    const history = useHistory()
    const {organization} = useContext(AppContext)

    const [selectedValue, setSelectedValue] = useState(localStorage.getItem("current-organization-id"));

    useEffect(() => {
        // for support across multiple tabs and keeping them all in sync
        // handling state mangment through storgae listener event
        // having the navigation here changes on all tabs
        const handleStorageChange = () => {
            setSelectedValue(localStorage.getItem("current-organization-id"));
            history.push("/dashboard")
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, [history]);

    if (!organization || organization instanceof Error || !organization.belongs) {
        return <></>
    }

    const changeCurrentOrganization = (newOrganizationId: string) => {
        localStorage.setItem("current-organization-id", newOrganizationId)

        // storage events don't fire within the same tab
        window.dispatchEvent(new StorageEvent("storage"))
    }

    if (!selectedValue) {
        changeCurrentOrganization(organization.organizations[0].id)
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value as string
        changeCurrentOrganization(newValue)
    }

      return (
        <FormControl>
            <Select
            value={selectedValue!}
            onChange={handleChange}
            displayEmpty
            variant="outlined"
            style={{color: theme.palette.primary.main, background: theme.palette.secondary.contrastText}}
            >
            {organization.organizations.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                {option.auth0DisplayName}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
      )
}

export default OrganizationSelector
