import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useEffect, useState, type FC } from "react";
import React from "react";
import { TSystemNameDictionary } from "../../../pages/Map";
import { Feature, Geometry } from "geojson";

// todo: ideally this can happen much earlier / higher up in the component tree
export function sortProperties(propsByLayerNameObj: any, sourceLayer: string, featureProperties: { [name: string]: any }[]) {
    let sortedFeaturePropertiesArr: { name: string, value: unknown }[] = [];
    if (!propsByLayerNameObj[sourceLayer]) {
        return sortedFeaturePropertiesArr // this returns an empty array if anyone accidentally selects something like the connector line momentarily. probably should disable the map onclick function while editing.
    }

    const propsByLayerName = propsByLayerNameObj[sourceLayer]


    for (const propKey of propsByLayerName.properties) {
        if (propKey === '_id') {
            // skip and don't render the _id
            continue
        }
        sortedFeaturePropertiesArr.push({ name: propKey, value: featureProperties?.find?.((nv: any) => nv.name === propKey)?.value })
    }
    return sortedFeaturePropertiesArr
}

export type TUpdateFeatureTabFormStoreValues = {
    geometry?: any
    properties: { name: string, value: unknown }[]
    id?: string | number
}

type UpdateFeatureTabProps = {
    selectedFeature: { layerName: string, feature: Feature } | undefined
    proposalInfo: any | null
    propsByLayerNameObj?: any
    systemNameDictionary: TSystemNameDictionary | undefined

    updateFeature: (feature: any) => Promise<void>
    moveFeature: () => void
    cancelUpdateFeature: () => void
    focusFeatureOnMap: (geometry: Geometry, zoom?: number) => void
}

export const UpdateFeatureTab: FC<UpdateFeatureTabProps> = React.memo(({ selectedFeature, proposalInfo, propsByLayerNameObj, systemNameDictionary, updateFeature, moveFeature, cancelUpdateFeature, focusFeatureOnMap }) => {
    const [saving, setIsSaving] = useState(false)
    const [moving, setIsMoving] = useState(false)
    const [sourceLayer, setSourceLayer] = useState<string>("")
    const [changedFields, setChangedFields] = useState<Set<string>>(new Set())
    const [formValues, setFormValues] = useState<TUpdateFeatureTabFormStoreValues>({ geometry: null, properties: [], })

    // feature useEffect
    // sets the feature data to the form
    useEffect(() => {
        if (!selectedFeature?.feature.properties) return
        const properties = Object.entries(selectedFeature.feature.properties).map(([key, value], i) => ({ name: key, value: value }))

        // need to reset this if the feature changed
        setChangedFields(new Set())
        // on save the the feature doesn't have the sourceLayer field
        if (selectedFeature.layerName) {
            setSourceLayer(selectedFeature.layerName)
        }
        const sortedProperties = sortProperties(propsByLayerNameObj, selectedFeature.layerName, properties)
        setFormValues({
            geometry: selectedFeature.feature.geometry,
            properties: sortedProperties,
            id: selectedFeature.feature.id,
        });
    }, [propsByLayerNameObj, selectedFeature?.feature.geometry, selectedFeature?.feature.id, selectedFeature?.feature.properties, selectedFeature?.layerName])

    // proposalInfo useEffect
    // sets the "Latitude, Longitude, and DateUpdate on the form"
    useEffect(() => {

        if (proposalInfo && systemNameDictionary && sourceLayer) {
            let proposedProps: { name: string, value: string }[] = [];

            const fields = systemNameDictionary[sourceLayer].fields
            const propertiesToChange = ["Latitude", "Longitude", "DateUpdate"].map(systemName => Object.keys(fields).find(sourceField => fields[sourceField] === systemName)).filter(source => source !== undefined)

            proposalInfo.properties.forEach((prop: { name: string, value: string }) => {
                proposedProps.push({
                    name: prop.name,
                    value: prop.value
                })
            })

            propertiesToChange.forEach(propertyNameToChange => {
                const matchingItem = proposalInfo.properties.find((updatedItem: { name: string; }) => updatedItem.name === propertyNameToChange);
                const existingIndex = proposedProps.findIndex(
                    item => item.name === matchingItem.name
                );

                if (existingIndex !== -1) {
                    proposedProps.splice(existingIndex, 1);
                }

                proposedProps.push({ ...matchingItem });
            })



            setIsMoving(false)
            setFormValues(prev => {

                setChangedFields(prevSet => {
                    const updatedSet = new Set(prevSet);
                    proposedProps.forEach((property: { name: string, value: string }) => {
                        const changedProperty = prev.properties.find((curValue: {
                            value: unknown; name: string
                        }) => {
                            return curValue.name === property.name && curValue.value !== property.value;
                        })

                        if (changedProperty) {
                            updatedSet.add(changedProperty.name)
                        }

                    })
                    return updatedSet;
                })

                return {
                    properties: proposedProps,
                    geometry: proposalInfo.geometry,
                    id: prev.id,
                }
            });
        }

    }, [proposalInfo, sourceLayer, systemNameDictionary])

    // changeFields useEffect
    // updates the DateUpdate if another field has been updated
    useEffect(() => {
        if (systemNameDictionary && systemNameDictionary[sourceLayer] && changedFields.size > 0) {
            const fields = systemNameDictionary[sourceLayer].fields
            const sourceField = Object.keys(fields).find(sourceField => fields[sourceField] === "DateUpdate")
            if (sourceField) {
                const currentDate = new Date();
                const currentDateISO = currentDate.toISOString().split('.')[0] + 'Z';

                if (!changedFields.has(sourceField)) {
                    setChangedFields(prev => {
                        let newSet = new Set(prev)
                        newSet.add(sourceField)
                        return newSet
                    })
                }

                setFormValues(prev => ({
                    geometry: prev.geometry,
                    properties: prev.properties ? prev.properties.map(property =>
                        property.name === sourceField
                            ? { ...property, value: currentDateISO } // Update the DateUpdate
                            : property
                    ) : prev.properties,
                    id: prev.id,
                }));
            }
        }
    }, [changedFields, sourceLayer, systemNameDictionary])

    const move = () => {
        setIsMoving(true)
        moveFeature()
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyIndex: number) => {
        let property = formValues.properties[propertyIndex]
        setChangedFields(prev => {
            let newSet = new Set(prev)
            newSet.add(property.name)
            return newSet
        })
        formValues.properties[propertyIndex].value = event.target.value
        setFormValues(formValues)
    }

    const onSave = async () => {
        setIsSaving(true)
        let properties = formValues.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
        await updateFeature({
            type: "Feature",
            geometry: formValues.geometry,
            properties: properties,
            id: formValues.id
        });

        setFormValues({
            geometry: formValues.geometry,
            properties: formValues.properties,
            id: formValues.id,
        });
        setChangedFields(new Set())
        setIsSaving(false)
    }

    const cancel = () => {
        if (!selectedFeature?.feature.properties) return
        setChangedFields(new Set())
        cancelUpdateFeature()
        const properties = Object.entries(selectedFeature.feature.properties).map(([key, value], i) => ({ name: key, value: value }))

        setFormValues({
            geometry: selectedFeature.feature.geometry,
            properties: sortProperties(propsByLayerNameObj, selectedFeature.layerName, properties),
            id: selectedFeature.feature.id,
        });
        setIsMoving(false)
    }

    return (
        <>
            <Grid container direction={'row'} alignItems={'normal'} spacing={1}>
                <Grid item xs={3}>
                    <Tooltip title="Cancel">
                        <Button type="reset" disabled={saving} onClick={() => cancel()}><CancelIcon /></Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={3} >
                    <Tooltip title="Move">
                        <Button disabled={saving} onClick={() => move()}><OpenWithIcon /></Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={3} >
                    <Tooltip title="Focus">
                        <Button disabled={(!selectedFeature || !selectedFeature.feature)}
                            onClick={() => focusFeatureOnMap(selectedFeature!.feature!.geometry, 18)} ><LocationSearchingIcon /></Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>
                    <Tooltip title="Save">
                        <Button type="submit" onClick={() => onSave()} disabled={saving || moving}><SaveIcon /></Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <TableContainer >
                <Table size={'small'}>
                    <TableBody>
                        {formValues.properties?.map((_, i) => {
                            let conditionalStyles = null
                            if (changedFields.has(formValues.properties[i].name)) {
                                conditionalStyles = { backgroundColor: 'rgb(202, 210, 255)', opacity: .8 }
                            }

                            return (
                                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...conditionalStyles }}>
                                    <TableCell>
                                        <TextField label={formValues.properties[i].name}
                                            InputLabelProps={{
                                                shrink: true,  // Forces the label to stay above the input field
                                            }}
                                            variant="outlined"
                                            size="small"
                                            margin="normal"
                                            sx={{ width: '100%' }} onChange={(event) => handleOnChange(event, i)} value={formValues.properties[i].value} />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
})
