import axios from "axios";
import qs from "qs";
import { TValidation } from "./validationsController";
import { GridGetRowsParams, GridGetRowsResponse } from "@mui/x-data-grid-pro";

/**
 *     public class GetFeatureAttributesModel
    {
        public bool Success { get; set; }
        public string Type { get; set; }
        public IEnumerable<string> Attributes { get; set; }
    }
 */
export type TGetLayerFeaturePropertyOrders = {
    layers: {
        layerName: string;
        properties: string[];
    }[]
}
export const getAllLayerFeaturePropertyOrders = async (
    accessToken: string,
    organizationId: string,
    fileUploadId: string,
) => {
    return axios.get<TGetLayerFeaturePropertyOrders>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/all-layer-feature-property-orders`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    );
};

export type TGetFeaturePropertiesByLayerNameModel = {
    success: boolean;
    layerName: string;
    properties: string[];
};

export const getFeaturePropertiesByLayerName = async (
    accessToken: string,
    layerName: string,
    organizationId: string,
    fileUploadId: string,
) => {
    return axios.get<TGetFeaturePropertiesByLayerNameModel>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/layername/properties/${layerName}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    );
};

// stub a new async function got gettingFeatureValidationsByLayerName
// layername/validations/{layerName}

export type TGetFeatureValidationsByLayerNameModel = {
    validationRules: string[];
};
export const getFeatureValidationsByLayerName = async (
    accessToken: string,
    layerName: string,
    organizationId: string,
    fileUploadId: string,
) => {
    return axios.get<TGetFeatureValidationsByLayerNameModel>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/layername/validations/${layerName}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    );
}

export type TGetFeaturesFromFile = {
    features: TFeature[]
    maxCount: number
}

export type TFeature = {
    layerName: string
    featureId: string;
    properties: TProperty[]
    // todo: improve this type
    validations?: TValidation[]
}

export type TProperty = {
    key: string
    value: string
}


export const getFeatureByLayerName = async (
    accessToken: string,
    layerName: string,
    organizationId: string,
    fileUploadId: string,
    page: number,
    pageSize: number,
    fields?: string[],
    searchTerm?: string,
    validationRuleFilter?: string
) => {

    let params: Record<string, any> = {
        organizationId: organizationId,
        fileUploadId: fileUploadId,
        page: page,
        pageSize: pageSize,
    }

    if (searchTerm && searchTerm !== '') {
        params.searchTerm = searchTerm
    }

    if (fields && fields.length > 0) {
        params.fields = fields
    }

    if (validationRuleFilter && validationRuleFilter !== '') {
        params.validationRuleFilter = validationRuleFilter
    }

    return axios.get<TGetFeaturesFromFile>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/layername/${layerName}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat', encode: false })
            }
        },
    )
};

export type TGridGetRowsRequestPlus =  {
    accessToken: string
    organizationId: string
    fileUploadId: string
    layerName: string
    validationRuleFilter?: string
    gridGetRowsParams: Omit<GridGetRowsParams, 'start' | 'end'>
}
export const getFeaturesForGrid = (inputs: TGridGetRowsRequestPlus) => {
    return axios.post<GridGetRowsResponse>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/layer/${inputs.layerName}`,
        {
            ...inputs.gridGetRowsParams
        },
        {
            headers: {
                Authorization: `Bearer ${inputs.accessToken}`,
            },
            params: {
                organizationId: inputs.organizationId,
                fileUploadId: inputs.fileUploadId,
                validationRuleFilter: inputs.validationRuleFilter,
            }
        },
    )
}

//TODO CHRIS TYPE THIS RESPONSE
export const getFeatureById = async (
    accessToken: string,
    organizationId: string,
    fileUploadId: string,
    featureId: string,
) => {

    return axios.get<any>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/${featureId}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    )
};

export type TGetFeaturesLayerNameWithFeatures = {
    id: string,
    // todo type this
    featureList: any[]
}
export const getFeatureBySearchTerm = async (
    accessToken: string,
    organizationId: string,
    fileUploadId: string,
    searchTermstring: string
) => {

    return axios.get<TGetFeaturesLayerNameWithFeatures[]>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/search-term/${searchTermstring}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    )
};

