import axios from "axios"

const BASE_URL = `${process.env.REACT_APP_API_SERVER_URL}/v1/models`

export type TGetFUModelsRespBody = {
    success: boolean
    fuModels: any[] // todo: type this
}
export const getFileUploadModels = async (accessToken: string, organizationId: string, fileUploadId: string) => {
    return axios.get<TGetFUModelsRespBody>(
        BASE_URL,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId
            }
        },
    )
}

export type TGetTemplatesRespBody = {
    success: boolean
    templates: any[] // todo: type this
}
export const getTemplates = async (accessToken: string) => {
    return axios.get<TGetTemplatesRespBody>(
        `${BASE_URL}/templates`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        },
    )
}

export type TCreateFUModelRespBody = {
    success: boolean
    fuModel: any // todo: type this
}
export const createFUModel = async ({ accessToken, organizationId, fileUploadId, dataModelId }: { accessToken: string, organizationId: string, fileUploadId: string, dataModelId: string }) => {
    return axios.post<TCreateFUModelRespBody>(
        BASE_URL,
        {
            OrganizationId: organizationId,
            FileUploadId: fileUploadId,
            DataModelId: dataModelId
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        },
    )
}

// export type TGetMappingResponseBody = {
//   success: boolean
//   sourceLayers: any[] // todo: type this
//   sourceLayerFields: any[] // todo: type this
//   mapping: any // todo: type this
// }

// export const getMapping = async ({accessToken,organizationId,fileUploadId,modelId,layerMappingId}:{accessToken: string, organizationId: string, fileUploadId: string,modelId:string,layerMappingId?: string}) => {
//   return axios.get<TGetMappingResponseBody>(
//     `${BASE_URL}/${modelId}`,
//     {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//       params: {
//         organizationId,
//         fileUploadId,
//         layerMappingId
//       }
//     },
//   )
// }

export type TUpdateOrgFUModelResponseBody = {
    success: boolean
    organization: any // todo: type this
}

type TUpdateFUModelLayerReqBody = {
    source: string
    sourceName: string
    sourceType: string
} | {
    templateString: string
    setTemplateString: true
} | {
    SetAutoPopulateSources: boolean
    autoPopulateSources: [
        {
            sourceFieldName: string
            autoPopulateTemplateString: string
        }
    ]
}

export const updateFUModelLayer = async ({
    accessToken,
    organizationId,
    modelId,
    modelLayerId,
    fuModelLayer
}: {
    accessToken: string,
    organizationId: string,
    modelId: string,
    modelLayerId: string,
    fuModelLayer: TUpdateFUModelLayerReqBody
}) => {
    console.log("inside updateFUModelLayer--- ", fuModelLayer)
    return axios.put<TUpdateOrgFUModelResponseBody>(
        `${BASE_URL}/${modelId}/layers/${modelLayerId}`,
        {
            ...fuModelLayer
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export const updateFUModelLayerField = async ({ accessToken, organizationId, modelId, modelLayerId, modelLayerFieldId, modelLayerField }: { accessToken: string, organizationId: string, modelId: string, modelLayerId: string, modelLayerFieldId: string, modelLayerField: any }) => {
    return axios.put(
        `${BASE_URL}/${modelId}/layers/${modelLayerId}/fields/${modelLayerFieldId}`,
        {
            ...modelLayerField
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export const deleteFUModel = async ({ accessToken, organizationId, modelId }: { accessToken: string, organizationId: string, modelId: string }) => {
    return axios.delete(
        `${BASE_URL}/${modelId}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export type GenerateModelLayerPredictionRespBody = {
    success: boolean
    fuModelLayerFields: any // todo: type this
}
export const generateModelLayerPrediction = async ({ accessToken, organizationId, fileUploadId, fuLayerName, dataModelId, dataModelLayerId }: { accessToken: string, organizationId: string, fileUploadId: string, fuLayerName: string, dataModelId: string, dataModelLayerId: string }) => {
    return axios.put<GenerateModelLayerPredictionRespBody>(
        `${BASE_URL}/${dataModelId}/layers/${dataModelLayerId}/predict`,
        {
            fuLayerName: fuLayerName
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId
            }
        },
    )
}
