import { Box } from "@mui/material"
import { useRef, useState } from "react"
import { Map, MapRef } from 'react-map-gl'
import { DEFAULT_MAP_STYLE } from "../common"


export const BaseMapPage = () => {
    const mapRef = useRef<MapRef>(null)
    const [viewState, setViewState] = useState({
        latitude: 39.205867,
        longitude: -94.544264,
        zoom: 5.5,
    })
    return (
        <Box>
            <Map
                ref={mapRef}
                style={{ height: '100vh', width: '100vw' }}
                mapStyle={DEFAULT_MAP_STYLE}
                {...viewState}
                onMove={(event) => setViewState(event.viewState)}
            >
            </Map >
        </Box>
    )
}
