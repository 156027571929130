import {useAuth0} from '@auth0/auth0-react'
import {useEffect, useState} from 'react'

export default function useAccessToken() {
    const {isLoading, getAccessTokenSilently, user} = useAuth0()
    const [accessToken, setAccessToken] = useState("")

    useEffect(() => {
      if (isLoading === true || typeof getAccessTokenSilently !== 'function' || !user) {
        return
      }

      const getAccessToken = async () => {
            const _accessToken = await getAccessTokenSilently()
            if (!_accessToken) {
                return;
            }

            setAccessToken(_accessToken)
        }

        void getAccessToken()
    }, [isLoading, getAccessTokenSilently, user])

    return accessToken

  }
