import {withAuthenticationRequired} from '@auth0/auth0-react'
import React, {ComponentType} from 'react'
import {Route, RouteProps} from 'react-router-dom'
import {PageLoader} from './page-loader'

interface ProtectedRouteProps extends RouteProps {
  component: ComponentType
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({path, component, ...args}) => (
  <Route
    path={path}
    component={withAuthenticationRequired(component, {
      onRedirecting: () => {
        console.log('onRedirecting')
        return (
          <div className="page-layout">
            <PageLoader />
          </div>
        )
      },
    })}
    {...args}
  />
)
