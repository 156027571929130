import { useParams } from "react-router-dom";
import { FeatureTableV2 } from "../components/FeatureTableV2"
import useQueryParams from "../hooks/useQueryParams";
import { useContext } from "react";
import { AppContext } from "../App";

export const FileFeaturesV2: React.FC<{}> = () => {
    const { accessToken, organization: organizationsResult } = useContext(AppContext)
    const { fileUploadId } = useParams<{ fileUploadId: string }>()
    const qp = useQueryParams();
    const currentOrganizationId = qp.get("organizationId")

    if (!accessToken) {
        return <div>Error: Access token not found.</div>
    }

    if (organizationsResult instanceof Error || !organizationsResult?.belongs) {
        return (<div>Error: Unable to determine organization access.</div>)
    }

    const currentOrganization = organizationsResult.organizations.find((org) => org.id === currentOrganizationId);
    const fileUpload = currentOrganization?.fileUploads.find((file) => file.uploadId === fileUploadId);

    if (!currentOrganization && !fileUpload) {
        return <div>Error: Invalid current organization / file upload.</div>
    }

    return <FeatureTableV2 accessToken={accessToken} organization={currentOrganization!} fileUpload={fileUpload!} />
}
