import React, { useContext, useEffect, useState } from 'react';

import Shell from '../layout/Shell';
import { useQuery } from 'react-query';
import { AppContext } from '../App';
import { getTemplates } from '../api/modelsController';

import {
    Checkbox, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Table, TableContainer, TableBody,
    TableHead,
    TableRow,
    TableCell
} from '@mui/material';
import RuleIcon from '@mui/icons-material/Rule';
import { TSuccessOrganizationResult } from '../hooks/useOrganization';

const SettingsPage: React.FC = () => {
    const [currentOrganizationId] = useState(localStorage.getItem("current-organization-id"))
    const { accessToken, organization: orgsResult } = useContext(AppContext)

    console.log(`settings page for org ${currentOrganizationId}`)
    const { data: getTemplatesRespBody } = useQuery('settings-get-templates', () => {
        if (!accessToken) {
            console.log('NOT FETCHING templates')
            return
        }
        console.log('fetching templates')
        return getTemplates(accessToken)
    });

    const currentOrganization = (orgsResult as TSuccessOrganizationResult)?.organizations?.find?.(org => org.id === currentOrganizationId);
    const templates = getTemplatesRespBody?.data.templates
    const hasOrgDataModel = !!currentOrganization?.dataModelId

    type TLayer = {
        name: string, // layerName
        order: number,
        attributes: {
            fieldName: string,
            systemName: string,
            descriptiveName?: string,
            required?: string,
            type?: string,
            fieldWidth?: string,
            order?: number
        }[],
    }

    const [dataModelId, setDataModelId] = useState<string>()
    const [matchingTemplateLayers, setMatchingTemplateLayers] = useState<TLayer[]>([])
    const [selectedLayer, setSelectedLayer] = useState<TLayer>()

    type TValidationRule = {
        checked: boolean,
        rule: string
    }
    const [validationRules, setValidationRules] = useState<TValidationRule[]>([{ checked: false, rule: 'RCL_OVERLAP' }])

    // const [checked, setChecked] = React.useState([0]);
    const handleToggle = (value: string) => () => {
        const currentIndex = validationRules.findIndex((x) => x.rule === value);
        if (currentIndex === -1) {
            return
        }
        const newValidationRules = [...validationRules];
        newValidationRules[currentIndex].checked = !newValidationRules[currentIndex].checked;
        setValidationRules(newValidationRules);
    };

    useEffect(() => {
        console.log(`dataModelId: ${dataModelId}`)
        if (!templates || !dataModelId) {
            return
        }
        const matchingTemplate = templates?.find?.(t => t.objectId === dataModelId)
        const matchingTemplateLayers = matchingTemplate?.layers ?? []

        setMatchingTemplateLayers(matchingTemplateLayers)
    }, [templates, dataModelId])

    useEffect(() => {
        console.log(`selectedLayer: ${selectedLayer}`)
    }, [selectedLayer])

    return (
        <Shell>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>Settings</h1>
                </Grid>
                <Grid item xs={9}>
                    <h2>Data Models</h2>
                    {hasOrgDataModel && <p>Organization has a data model</p>}
                    {!hasOrgDataModel && <>
                        <Select
                            sx={{ minWidth: '12rem' }}
                            label="Select a template"
                            value={dataModelId}
                            onChange={(e) => {
                                // console.log('selecting template', JSON.stringify(e.target.value, null, 2))
                                setDataModelId(e.target.value as string)
                            }}>
                            {templates?.map?.(t => <MenuItem key={t.objectId} value={t.objectId}>{t.typeName}</MenuItem>)}
                        </Select>
                    </>}
                    {(hasOrgDataModel || dataModelId) && (<>
                        <Select
                            sx={{ minWidth: '12rem' }}
                            label="Select a layer"
                            value={selectedLayer}
                            onChange={(e) => {
                                // console.log('selecting layer', JSON.stringify(e.target.value, null, 2))
                                setSelectedLayer(matchingTemplateLayers.find(l => l.name === e.target.value))
                            }}>
                            {matchingTemplateLayers.sort((a, b) => {
                                return a.order - b.order
                            }).map((layer) => <MenuItem key={layer.name} value={layer.name}>{layer.name}</MenuItem>)}
                        </Select>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Field Name</TableCell>
                                        <TableCell>System Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Field Width</TableCell>
                                        <TableCell>Required</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedLayer && selectedLayer.attributes.map(attr => (
                                        <TableRow key={attr.fieldName}>
                                            <TableCell>{attr.fieldName}</TableCell>
                                            <TableCell>{attr.systemName}</TableCell>
                                            <TableCell>{attr.descriptiveName}</TableCell>
                                            <TableCell>{attr.type}</TableCell>
                                            <TableCell>{attr.fieldWidth}</TableCell>
                                            <TableCell>{attr.required}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>)}
                </Grid>
                <Grid item xs={3}>
                    {/* TODO: Add layername dynamically */}
                    <h2>Validation Rules</h2>
                    {/* <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}> */}
                    <List>
                        {validationRules.map((value) => {
                            const labelId = `checkbox-list-label-${value.rule}`;

                            return (
                                <ListItem
                                    key={value.rule}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="rule">
                                            <RuleIcon />
                                        </IconButton>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton onClick={handleToggle(value.rule)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={value.checked}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={value.rule} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
            </Grid>
        </Shell>
    )
}

export default SettingsPage
