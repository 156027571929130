import { TOrganization } from "./hooks/useOrganization";

type TOrgKeyNames = 'LosAlamos' | 'Kansas' | 'NorthDakotaSioux'

export const HC_ORGS: Record<TOrgKeyNames, Pick<TOrganization, 'id' | 'auth0Name'>> = {
    LosAlamos: {
        id: 'org_UoEatuhAzGohOxOQ',
        auth0Name: 'losalamos'
    },
    Kansas: {
        id: 'org_807pNICSJ9oLZzI0',
        auth0Name: 'ks-dickenson'
    },
    NorthDakotaSioux: {
        id: 'org_BIubguLZPQhCT9h7',
        auth0Name: 'nd-sioux'
    }
}
