export type TSourceFeatureData = {
    /**
     * The name of the layer that the feature belongs to in the source data.
     */
    layer: string;
    /**
     * Generic object with string keys and unknown values.
     */
    properties: { [key: string]: unknown };
}

/**
 * Replaces the matching template string with the matching properties by key name.
 *
 * @param templateString The possible template strings to use for matching by layer name and then replacing with properties.
 * @param featureData The feature data to use for replacement.
 * @returns The template string with the properties replaced.
 */
export const findAndReplaceLayerTemplateString = (templateStringsMap: Map<string, string>, featureData: TSourceFeatureData): string => {
    const templateString = templateStringsMap.get(featureData.layer);
    if (templateString) {
        return templateString.replace(/\${([^}]+)}/g, (match, key) => {
            return featureData.properties[key] as string;
        });
    }
    return '';
}

/**
 * Replaces the matching template string with the matching properties by key name.
 *
 * @param templateString The possible template strings to use for matching by layer name and then replacing with properties.
 * @param featureData The feature data to use for replacement.
 * @returns The template string with the properties replaced.
 */
export const findAndReplaceFieldAutoPopulateTemplateString = (templateString: string, featureData: [{ name: string, value: string }]): string => {
    if (templateString) {
        return templateString.replace(/\${([^}]+)}/g, (match, key) => {
            return featureData.find((fd: any) => {
                return fd.name === key
            })?.value as string
        });
    }
    return '';
}
