import { Grid, Slider, Typography } from "@mui/material";
import { TRoadConfig, TRoadStyle } from "../../../hooks/useOrganization";
import { useEffect, useState } from "react";
import { ControlledColorPicker } from "./ControlledColorPicker";

const SettingsColorPicker: React.FC<{
    style: TRoadStyle,
    onChange: (color: string) => void
}> = (props) => {
    const [color, setColor] = useState<string>(props.style.color);
    return (
        <Grid container>
            <Grid xs={8}>
                {props.style.propertyName} = {props.style.propertyValue}
            </Grid>
            <Grid xs={4}>
                <ControlledColorPicker
                    color={color}
                    onChange={(color) => {
                        setColor(color)
                        props.onChange(color)
                    }}
                />
            </Grid>
        </Grid>
    )
}

type TZoomSliderProps = {
    style: TRoadStyle
    onChange: (zoom: number) => void
}
const ZoomSlider: React.FC<TZoomSliderProps> = (props) => {
    const [sliderValue, setSliderValue] = useState<number>(props.style.minZoom);

    return (
        <Slider
            value={sliderValue}
            onChange={(_event, newValue) => {
                setSliderValue(newValue as number)
                props.onChange(newValue as number)
            }}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `Minimum Zoom: ${value}`}
            min={0}
            max={22} />
    )
}

type TSettingsTabProps = {
    currentZoom: number;
    roadConfig?: TRoadConfig
    onInMemoryRoadConfigChange: (roadConfig: TRoadConfig) => void
}

export const SettingsTab: React.FC<TSettingsTabProps> = (props) => {
    const [inMemoryRoadConfig, setInMemoryRoadConfig] = useState<TRoadConfig | undefined>(props.roadConfig)

    useEffect(() => {
        if (inMemoryRoadConfig) {
            props.onInMemoryRoadConfigChange(inMemoryRoadConfig)
        }
    }, [inMemoryRoadConfig, props])

    return (
        <div>
            <Typography variant="body2"><strong>Current Zoom:</strong> {props.currentZoom}</Typography>
            <br />
            <Typography variant="h6">{inMemoryRoadConfig?.layerName}</Typography>
            {inMemoryRoadConfig?.styles.map((style, idx) => {
                return (
                    <div key={`${style.propertyName}_${style.propertyValue}_${style.minZoom}`}>
                        <SettingsColorPicker style={style} onChange={(updatedColor) => {
                            // this is a hack
                            console.log('update color in db', updatedColor)
                            const x = inMemoryRoadConfig?.styles.map((s, i) => {
                                if (i === idx) {
                                    return { ...s, color: updatedColor }
                                }
                                return s
                            }) || [];
                            setInMemoryRoadConfig({
                                layerName: inMemoryRoadConfig?.layerName ?? '',
                                styles: x
                            })
                        }} />
                        <ZoomSlider style={style} onChange={(updatedMinZoom) => {
                            // this is a hack
                            console.log('update min zoom in db', updatedMinZoom)
                            const x = inMemoryRoadConfig?.styles.map((s, i) => {
                                if (i === idx) {
                                    return { ...s, minZoom: updatedMinZoom }
                                }
                                return s
                            }) || [];
                            setInMemoryRoadConfig({
                                layerName: inMemoryRoadConfig?.layerName ?? '',
                                styles: x
                            })
                        }} />
                    </div>
                )
            })}
        </div>
    );
};
