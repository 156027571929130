import { useQuery } from "react-query"
import { getFeaturePropertiesByLayerName } from "../api/featureController"

type TUseLayerPropertyNamesInput = {
    accessToken: string
    organizationId: string
    fileUploadId: string
    layerName: string
}
export const useLayerPropertyNames = ({
    accessToken,
    organizationId,
    fileUploadId,
    layerName
}: TUseLayerPropertyNamesInput): {
    layerProperties: string[];
} => {
    const { data: layerProperties } = useQuery(['get-layer-property-names', layerName], () => {
        if (!accessToken || !organizationId) {
            return
        }
        return getFeaturePropertiesByLayerName(accessToken, layerName, organizationId, fileUploadId)
    })

    if (!layerProperties || !layerProperties.data) {
        return { layerProperties: [] }
    }

    return { layerProperties: layerProperties.data.properties }
}
