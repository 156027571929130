import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import OrganizationSelector from '../components/OrganizationSelector'
import { HC_ORGS } from '../hardCodedOrgs';

type TShellProps = {
    children: React.ReactNode
    hideIcons?: boolean
}
type TNavItem = {
    key: string
    text: string
    icon?: (color: string) => React.ReactNode
    onClick: () => void
}
const Shell: React.FC<TShellProps> = (props) => {
    const drawerWidth = 240
    const [mobileOpen, setMobileOpen] = useState(false)
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
    const history = useHistory()
    const [currentOrgId] = useState(localStorage.getItem("current-organization-id"));

    const navItems: (TNavItem | undefined)[] = props.hideIcons === true ? [] : isAuthenticated
        ? ([
            {
                key: 'dashboard',
                text: 'Home',
                icon: (color: string) => <HomeIcon htmlColor={color} />,
                onClick: () => {
                    history.push('/dashboard')
                },
            },
            // only shown for los alamos
            currentOrgId === HC_ORGS.LosAlamos.id ? {
                key: 'settings',
                text: 'Settings',
                icon: (color: string) => <SettingsIcon htmlColor={color} />,
                onClick: () => {
                    history.push('/settings')
                },
            } : undefined,
            {
                key: 'logout',
                text: 'Logout',
                icon: (color: string) => <LogoutIcon htmlColor={color} />,
                onClick: () => {
                    logout({
                        returnTo: window.location.origin,
                    })
                },
            },
        ].filter((x) => !!x) as TNavItem[])
        : [
            {
                key: 'login',
                text: 'Login',
                onClick: async () => {
                    await loginWithRedirect({
                        prompt: 'login',
                        appState: {
                            returnTo: '/dashboard',
                        },
                    })
                },
            },
            {
                key: 'signup',
                text: 'Signup',
                onClick: async () => {
                    await loginWithRedirect({
                        prompt: 'login',
                        screen_hint: 'signup',
                        appState: {
                            returnTo: '/profile',
                        },
                    })
                },
            },
        ]

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState)
    }

    const mobileDrawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                911Geo
            </Typography>
            <Divider />

            <List>
                {(navItems as TNavItem[]).map((ni) => {
                    const { key, text, icon, onClick } = ni
                    return (
                        <ListItem key={key} disablePadding>
                            <ListItemButton sx={{ textAlign: 'center' }} onClick={onClick}>
                                <ListItemIcon>
                                    {typeof icon === 'function' && icon('black')}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )

    return (
        <Box>
            <Box>
                <AppBar component="nav">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { sm: 'block' } }}>
                            911Geo
                        </Typography>
                        <OrganizationSelector />

                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            {(navItems as TNavItem[]).map(({ key, text, icon, onClick }) => (
                                <Tooltip key={key} title={text}>
                                    <span>
                                        <IconButton onClick={onClick}>
                                            {typeof icon === 'function' && icon('white')}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {mobileDrawer}
                    </Drawer>
                </Box>
            </Box>
            <Box sx={{ mt: '64px' }}>{props.children}</Box>
        </Box>
    )
}

export default Shell
