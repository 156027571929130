import { Box, Button, Popover } from "@mui/material";
import { useState } from "react";
import { SketchPicker } from "react-color";

type ColorPickerProps = {
    color: string,
    onChange: (color: string) => void
}

export const ControlledColorPicker: React.FC<ColorPickerProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState(null); // For Popover positioning
    const [open, setOpen] = useState(false); // For controlling Popover visibility

    // Handle click to open the color picker
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    // Handle closing of the color picker
    const handleClose = () => {
        setOpen(false);
    };

    // Handle color change and close the picker
    const handleChangeComplete = (color: any) => {
        props.onChange(color.hex)
    };

    // Get RGB values from the color
    return (
        <Box onClick={e => { e.stopPropagation() }}>
            <Button
                variant="contained"
                onClick={handleClick}
                sx={{ backgroundColor: props.color, padding: '10px 10px', minWidth: '20px' }}
            >
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SketchPicker
                    color={props.color}
                    onChangeComplete={handleChangeComplete}
                />
            </Popover>
        </Box>
    );
};
